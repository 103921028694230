import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Bg from '../Assets/Images/landing.jpg'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingTop: 70,
        paddingTop: 70,
        height: "calc(100vh)",
        maxHeight: 1440,
        backgroundColor: "black",
        background: `url(${Bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        fontSize: "62.5%",
        "& p": {
            color: "white",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "45%",
            height: "calc(100vh)",
        }
    },
    container: {
        paddingLeft: 20,

    },
    mainHeading: {
        fontSize: "5em",
        fontWeight: "bold",
        "& b": {
            color: "#1C93D8",
        },
        marginBottom: "1em",
        [theme.breakpoints.down('xs')]: {
            marginBottom: "2em",
        },
    },
    para: {
        maxWidth: 600,
        fontSize: "1.6em",
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,

        }
    },
    lineWrapper: {
        display: "flex",
        marginTop: "2.5em",
        marginBottom: "5em",
    },
    line: {
        borderRadius: "360px",
        background: "#1C93D8",
        height: 8,
        marginRight: 15,
    },
    // btn: {
    //     color: "#1C93D8",
    //     padding: "20px 30px",
    //     border: "2px solid #1C93D8",
    //     display: "inline-flex",
    //     cursor: "default",

    //     "&:hover": {
    //         "& svg": {
    //             transition: "all 300ms ease-in-out",
    //             transform: "translateX(10px)",
    //         }
    //     },
    // },
    btnAnimated: {
        textDecoration: "none",
        position: "relative",
        margin: "auto",
        padding: "19px 22px",
        fontFamily: "Avenir, sans-serif",
        color: "white",
        transition: "all .2s ease",
        cursor: "pointer",
        "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            display: "block",
            borderRadius: "28px",
            background: "rgba(28,147,216,1)",
            width: "56px",
            height: "56px",
            [theme.breakpoints.down('xs')]: {
                width: "42px",
                height: "42px",
                top: 3,
            },
            transition: "all 0.3s ease",
        },
        "& span": {
            position: "relative",
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: 900,
            letterSpacing: ".25em",
            textTransform: "uppercase",
            verticalAlign: "middle",
            [theme.breakpoints.down('xs')]: {
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: "0.1em",
            },
        },
        "& svg": {
            position: "relative",
            top: 3,
            marginLeft: "10px",
            fill: "none",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            stroke: "white",
            strokeWidth: 2,
            transform: "translateX(-5px)",
            transition: "all .3s ease",
        },
        "&:hover": {
            "&:before": {
                width: "100%",
                background: "rgba(28,147,216,1)",
            },
            "& svg": {
                transform: "translateX(0)",
            },
        },
        "&:active": {
            transform: "scale(.96)",
        }

    },
}))


const Landing = (props) => {
    const classes = useStyles();

    const scroll = () => {
        props.refs["aboutRef"].current.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div className={classes.wrapper} ref={props.refProp}>
            <Container className={classes.container}>
                <div>
                    <Typography className={classes.mainHeading}>WELCOME TO <b>NOVASOFT</b></Typography>
                    <Typography className={classes.para}>
                        We focused a lot to put a perfect idea on the table to provide stable and a perfect plan for your business, which can run smoothly in today’s world.<br></br><br></br>
                        Apart from professional experience our developers have the potential to transform any idea into code. Our services are unique because we provide every possible outcome for our clients and the promise to make it possible.
                    </Typography>
                    <div className={classes.lineWrapper}>
                        <div style={{ width: 124 }} className={classes.line}></div>
                        <div style={{ width: 20 }} className={classes.line}></div>
                        <div style={{ width: 10 }} className={classes.line}></div>
                    </div>
                    <a className={classes.btnAnimated} onClick={props.getStarted ? props.getStarted : props.refs ? scroll : null}>
                        <span>Get Started</span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </a>
                    {/* <div className={classes.btn} >Get Started <ArrowForwardIcon/></div> */}
                </div>
            </Container>
        </div>
    );
}

export default Landing;
