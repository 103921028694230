import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import BG from "../Assets/Images/Team-Bg.png";
import PeopleFacebookIcon from "../Assets/Icons/Team/facebook.svg";
import PeopleGithubIcon from "../Assets/Icons/Team/github.svg";
import PeopleDribbleIcon from "../Assets/Icons/Team/dribble.svg";
import PeopleInstagramIcon from "../Assets/Icons/Team/instagram.svg";
import PeopleTwitterIcon from "../Assets/Icons/Team/twitter.svg";
import PeopleLinkedinIcon from "../Assets/Icons/Team/linkedin.svg";
// import PeopleYoutubeIcon from '../Assets/Icons/Team/youtube.svg';

import TeamData from "./TeamData";
import Portfolio from "./Portfolio";

const verticleCardStyles = makeStyles((theme) => ({
  wrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    marginTop: 20,
  },
  card: {
    background: "white",
    boxShadow: "2px 3px 20px rgba(0,0,0,0.2)",
    // boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    height: "750px",
    display: "grid",
    gridTemplateRows: "min-content 1fr",
    [theme.breakpoints.down("xs")]: {
      height: "635px",
    },
  },
  content: {
    padding: 20,
    height: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  connects: {
    display: "flex",
    // marginTop: 20,
    "& div": {
      width: 45,
      height: 45,
      padding: 20,
      background: "#F8F8F8",
      borderRadius: "50%",
      marginRight: 8,
    },
    "& img": {
      width: 18,
      height: 18,
    },
    [theme.breakpoints.down("sm")]: {
      "& div": {
        width: 40,
        height: 40,
        padding: 15,
        background: "#F8F8F8",
        borderRadius: "50%",
        marginRight: 5,
      },
      "& img": {
        width: 15,
        height: 15,
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& div": {
        width: 35,
        height: 35,
        padding: 10,
        background: "#F8F8F8",
        borderRadius: "50%",
        marginRight: 3,
      },
      "& img": {
        width: 15,
        height: 15,
      },
    },
  },
  mainHeading: {
    fontSize: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  heading: {
    marginTop: "15px",
    marginBottom: "20px",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginTop: "7px",
      marginBottom: "10px",
    },
  },

  para: {
    fontSize: 12,
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
}));

const VerticleCard = (props) => {
  const classes = verticleCardStyles();
  return (
    <div style={props.style} className={classes.wrapper}>
      <div className={classes.card}>
        <img width={"100%"} src={props.img} />

        <div className={classes.content}>
          <div>
            <h2 className={classes.mainHeading}>{props.name}</h2>
            <h4 className={classes.heading}>{props.title}</h4>
            <p className={classes.para}>{props.para}</p>
          </div>
          {/* <div className={classes.connects}>
            {props.facebook && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.facebook, "_blank")}
                  alt="facebook"
                  src={PeopleFacebookIcon}
                />
              </div>
            )}
            {props.dribble && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.dribble, "_blank")}
                  alt="dribble"
                  src={PeopleDribbleIcon}
                />
              </div>
            )}
            {props.github && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.github, "_blank")}
                  alt="github"
                  src={PeopleGithubIcon}
                />
              </div>
            )}
            {props.linkedin && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.linkedin, "_blank")}
                  alt="linkedin"
                  src={PeopleLinkedinIcon}
                />
              </div>
            )}
            {props.twitter && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.twitter, "_blank")}
                  alt="twitter"
                  src={PeopleTwitterIcon}
                />
              </div>
            )}
            {props.instagram && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.instagram, "_blank")}
                  alt="instagram"
                  src={PeopleInstagramIcon}
                />
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

const cardStyles = makeStyles({
  wrapper: {},
  card: {
    background: "white",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    maxWidth: 800,
    // width:"90%"
  },
  content: {
    // padding: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexFlow: "column",
    // height:"100%",
    justifyContent: "center",
    height: 330,
    paddingTop: 10,
    // alignItems:"center",
    // maxHeight:300,
  },
  connects: {
    display: "flex",
    marginTop: 20,
    "& div": {
      width: 50,
      height: 50,
      padding: 20,
      background: "#F8F8F8",
      borderRadius: "50%",
      marginRight: 10,
    },
    "& img": {
      width: 20,
      height: 20,
    },
  },
  mainHeading: {
    fontSize: 18,
  },
  heading: {
    marginTop: "5px",
    marginBottom: "10px",
    fontSize: 14,
  },
  para: {
    fontSize: 12,
    minHeight: 160,
  },
});

const Card = (props) => {
  const classes = cardStyles();
  let g1 = 7;
  let g2 = 5;
  let direction = "row";
  let imgWidth = "100%";
  let imgHeight = "100%";
  if (props.verticle) {
    g1 = 12;
    g2 = 12;
    direction = "column-reverse";
    imgWidth = "100%";
    imgHeight = "340px";
  }
  return (
    <div style={props.style} className={classes.wrapper}>
      <Grid className={classes.card} direction={direction} container>
        <Grid className={classes.content} item xs={g1}>
          <h2 className={classes.mainHeading}>{props.name}</h2>
          <h4 className={classes.heading}>{props.title}</h4>
          <p className={classes.para} style={{ paddingBottom: "110px" }}>
            {props.para}
          </p>
          {/* <div className={classes.connects} style={{ marginBottom: 10 }}>
            {props.facebook && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.facebook, "_blank")}
                  alt="facebook"
                  src={PeopleFacebookIcon}
                />
              </div>
            )}
            {props.dribble && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.dribble, "_blank")}
                  alt="dribble"
                  src={PeopleDribbleIcon}
                />
              </div>
            )}
            {props.github && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.github, "_blank")}
                  alt="github"
                  src={PeopleGithubIcon}
                />
              </div>
            )}
            {props.linkedin && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.linkedin, "_blank")}
                  alt="linkedin"
                  src={PeopleLinkedinIcon}
                />
              </div>
            )}
            {props.twitter && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.twitter, "_blank")}
                  alt="twitter"
                  src={PeopleTwitterIcon}
                />
              </div>
            )}
            {props.instagram && (
              <div className="flex">
                <img
                  onClick={() => window.open(props.instagram, "_blank")}
                  alt="instagram"
                  src={PeopleInstagramIcon}
                />
              </div>
            )}
          </div> */}
        </Grid>
        <Grid item xs={g2}>
          <img width={imgWidth} height={imgHeight} src={props.img} />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: `url(${BG})`,
    // paddingBottom: 30,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "left",
    backgroundPositionY: "top",
  },
  heading: {
    flexFlow: "column",
    padding: "20px 0px",
    marginBottom: 20,
  },
  headingText: {
    fontSize: 40,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  headingLine: {
    height: 8,
    background: "#1C93D8",
    width: 124,
    borderRadius: 360,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      height: 6,
    },
  },
  headingPara: {
    fontSize: 20,
    color: "black",
    textAlign: "center",
    maxWidth: 950,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `"a b""c b"`,
    rowGap: "10px",
    columnGap: "0px",
    padding: 10,
    marginBottom: 20,
  },
  secContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
  },

  aboveLG: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  belowLG: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));

const Team = (props) => {
  const [alicePadding, setPadding] = React.useState(0);

  React.useEffect(() => {
    const f = () => {
      if (window.innerWidth <= 485 && window.innerWidth >= 400) {
        setPadding(30);
      } else {
        setPadding(0);
      }
    };
    window.addEventListener("resize", f);
    f();
    return () => window.removeEventListener("resize", f);
  }, []);

  const classes = useStyles();
  const responsive = {
    desktop: {
      breakpoint: { max: 13000, min: 1280 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1280, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsiveAlice = {
    0: {
      items: 1,
    },
    485: {
      items: 2,
    },
    960: {
      items: 3,
    },
  };
  return (
    <div className={classes.wrapper} ref={props.refProp}>
      <div className={`flex ${classes.heading}`}>
        <Typography className={classes.headingText}>
          Our Skilled Team
        </Typography>
        <div className={classes.headingLine}></div>
        <Typography className={classes.headingPara}>
          Our team consists of highly skilled and experienced professionals who
          specialize in providing best services. Our expertise delivers by high
          performing, software solutions for your business needs in the most
          effective way. We help our customers and clients to make their
          business goals fulfill.
        </Typography>
      </div>
      <Container style={{ padding: "0px 20px" }}>
        {/* Below LG */}
        <div className={classes.belowLG}>
          <AliceCarousel
            mouseTracking
            autoPlay={true}
            infinite={true}
            responsive={responsiveAlice}
            autoHeight={true}
            autoPlayInterval={2000}
            disableButtonsControls={true}
            paddingLeft={alicePadding}
            paddingRight={alicePadding}
            items={[
              <VerticleCard verticle {...TeamData.zaman} />,
              <VerticleCard verticle {...TeamData.nouman} />,
              <VerticleCard verticle {...TeamData.hamza} />,
              <VerticleCard verticle {...TeamData.yasir} />,
              <VerticleCard verticle {...TeamData.shahbaz} />,
              // <VerticleCard
              //   verticle
              //   {...TeamData.saqlain}
              // />,
            ]}
          />
        </div>

        {/* <Carousel
                    className={classes.belowLG}
                    centerMode={false}
                    additionalTransfrom={2}
                    transitionDuration={1000}
                    autoPlay infinite={true}
                    autoPlaySpeed={2000}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    showDots={true}
                    responsive={responsive}
                    renderButtonGroupOutside={true}
                >
                    <VerticleCard
                        verticle
                        name="Syed Muhammad Zaman Mehdi"
                        title="Co-Founder & Frontend Head"
                        img={Zaman}
                        facebook="facebook.com"
                        github="github.com"
                        linkedin="linkedin.com"
                        twitter="twitter.com"
                        instagram="instagram.com"
                        para="Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Loreived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged.."
                    />
                    <VerticleCard
                        verticle
                        name="Nouman Suleman"
                        title="Co-Founder & Graphics Head"
                        img={Nouman}
                        facebook="facebook.com"
                        dribble="dribble.com"
                        linkedin="linkedin.com"
                        twitter="twitter.com"
                        instagram="instagram.com"
                        para="Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries,
                            but also the leap into electronic typesetting, remaining
                            essentially unchanged.."
                    />
                    <VerticleCard
                        verticle
                        name="Muhammad Hamza Khan"
                        title="Co-Founder & Backend Head"
                        img={Hamza}
                        facebook="facebook.com"
                        github="github.com"
                        linkedin="linkedin.com"
                        twitter="twitter.com"
                        instagram="instagram.com"
                        para="Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a
                        type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged.."
                    />
                </Carousel> */}
        {/* Above LG */}
        <Carousel
          className={classes.aboveLG}
          transitionDuration={1000}
          autoPlay
          infinite={true}
          autoPlaySpeed={2000}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          showDots={true}
          responsive={responsive}
          renderButtonGroupOutside={true}
        >
          {/* <div className={classes.cardsContainer}> */}
          <div className={classes.secContainer}>
            <Card
              style={{ gridArea: "a", marginBottom: "10px" }}
              {...TeamData.yasir}
            />
            <Card style={{ gridArea: "b", marginBottom: "10px" }} {...TeamData.nouman} />
            {/* <Card
              style={{ gridArea: "c" }}
              {...TeamData.hamza}
            /> */}
          </div>
          <div className={classes.secContainer}>
            {/* <Card
              verticle
              style={{ gridArea: "b" }}
              {...TeamData.saqlain}
            /> */}
            <Card style={{ gridArea: "b", marginBottom: "10px" }} {...TeamData.shahbaz} />
            <Card style={{ gridArea: "c", marginBottom: "10px" }} {...TeamData.zaman} />
          </div>
        </Carousel>
      </Container>
      <Portfolio />
    </div>
  );
};

export default Team;
