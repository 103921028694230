import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import BG from '../Assets/Images/Reviews-Bg.jpg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles(theme => ({
    wrapper: {
        background: `url(${BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontSize: "62.5%",
        paddingBottom:10,
        [theme.breakpoints.down('xs')]: {
            fontSize: "45%",
        }
    },
    container: {
        color: "white",
        padding: "0px 20px",
    },
    headingText: {
        fontSize: "4em",
        fontWeight: 600,
        textAlign: "center",
        paddingTop: "50px",
        [theme.breakpoints.down('xs')]:{
            fontSize: 20,
        }
    },
    headingLine: {
        height: 8,
        background: "#1C93D8",
        width: "70%",
        maxWidth: "400px",
        borderRadius: 360,
        marginBottom: "50px",
        [theme.breakpoints.down('xs')]:{
            marginTop:10,
            height: 6,
        }
    },
    para: {
        fontSize: "1.6em",
        marginBottom: "50px",
        textAlign: "center",
        // width:"92%",
        [theme.breakpoints.down('xs')]:{
            fontSize:14,
        }
    },
    name: {
        marginBottom: "50px",
        textAlign: "center",
        fontSize: "3em",
        fontWeight: "bold"
    }
}))

const Reviews = (props) => {
    const classes = useStyles();
    const responsive = {
        desktop: {
            breakpoint: { max: 13000, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div className={classes.wrapper} ref={props.refProp}>
            <Container className={classes.container}>
                <Typography className={classes.headingText}>
                    What our customer says about us
                </Typography>
                <div className="flex">
                    <div className={classes.headingLine}></div>
                </div>

                <Carousel
                    transitionDuration={1300}
                    // autoPlay 
                    infinite={true}
                    autoPlaySpeed={2300}
                    showDots={true}
                    responsive={responsive}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                >
                    <div style={{width:"90%",marginLeft:"5%"}}>
                        <Typography className={classes.para}>
                            <i>
                            The team has done amazing work for me, building a beautiful
                             responsive landing page thats looks 
                            amazing on the web and the mobile as well in a great delivery time.</i>
                        </Typography>
                        <Typography className={classes.name}>
                        Guy Crapp <br/> ASG, CTO
                        </Typography>
                    </div>

                    <div style={{width:"90%",marginLeft:"5%"}}>
                        <Typography className={classes.para}>
                            <i>
                            I really enjoy working with  Novasoft team, they are very competent,
                             professional, and very attentive to customer requirements, 
                             and in terms of deadlines in the realization of the project.
                            </i>
                        </Typography>
                        <Typography className={classes.name}>
                        Joel Z <br/> IntelloGeek SAS, CEO & Project Manager
                        </Typography>
                    </div>
                    <div >
                        <Typography className={classes.para}>
                            <i>
                            I hired Novasoft as a consultant to work on a react and nodejs project.
                             The task included setting the design layout and responsiveness for our admin dashboard and customer dashboard.
                              The task was complicated as we required very fine attention to detail.
                               Novasoft did a great job in understanding our needs,
                                creating a game plan, executing, and explaining changes that were made.
                                 Novasoft always had great communication every step of the way and
                                  as a native english speaker good communication was a requirement to prevent delays in our project.
                                   Overall I am satisfied with the outcomes and looking to hire again in the future
                            </i>
                        </Typography>
                        <Typography className={classes.name}>
                        Usama Choudry <br/> Project Manager
                        </Typography>
                    </div>

                    <div style={{width:"90%",marginLeft:"5%"}}>
                        <Typography className={classes.para}>
                            <i>
                            I enjoy working with NovaSoft. They delivers outstanding results and always on time.
                             The communication is decent and straightforward. 
                             Having a lot of expertise they are generous with advice on new technologies. Also, they makes sure,
                             their client understands the work and processes behind the projects.
                            </i>
                        </Typography>
                        <Typography className={classes.name}>
                        Ameer Hamza <br/> Project Manager
                        </Typography>
                    </div>
                </Carousel>
            </Container>
        </div>
    );
};


export default Reviews;
