import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import TrophyIcon from "../Assets/Icons/trophy.svg";
import About1 from "../Assets/Images/About-1.jpg";
import About2 from "../Assets/Images/About-2.jpg";
import About3 from "../Assets/Images/About-3.jpg";
import PlanningIcon from "../Assets/Icons/planning.svg";
import VisionIcon from "../Assets/Icons/vision.svg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: "#F8F8F8",
    paddingBottom: 30,
    fontSize: "62.5%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "50%",
    },
  },
  heading: {
    flexFlow: "column",
    padding: "2em 0px",
    marginBottom: "2em",
  },
  headingText: {
    fontSize: "4em",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  headingLine: {
    height: 8,
    background: "#1C93D8",
    width: 124,
    borderRadius: 360,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      height: 6,
    },
  },
  headingPara: {
    fontSize: 20,
    color: "black",
    textAlign: "center",
    maxWidth: 950,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  cardWrapper: {
    background: "#FFFFFF",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    height: "auto",
    marginBottom: "2em",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 0.5fr",
      gridTemplateAreas: '"text""image"',
    },
  },
  textCard: {
    [theme.breakpoints.down("sm")]: {
      gridArea: "text",
    },
  },
  imageCard: {
    [theme.breakpoints.down("sm")]: {
      gridArea: "image",
    },
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  cardRect: {
    height: "93px",
    width: "253px",
    color: "white",
    fontSize: "4em",
    fontWeight: 600,
    justifyContent: "flex-end",
    padding: "0px 1em",
    [theme.breakpoints.down("xs")]: {
      height: "70px",
      width: "200px",
    },
  },
  icons: {
    width: "8em",
    height: "8em",
    borderRadius: "50%",
    position: "relative",
    top: "-4em",
  },
  cardContent: {
    padding: "0 3em",
    position: "relative",
    top: "-4em",
  },
  cardContentHeading: {
    fontSize: "3em",
    fontWeight: 800,
    margin: "1em 0",
  },
  para: {
    fontSize: "1.6em",
    fontWeight: "regular",
  },
  rightIcon: {
    left: "13.3em",
    [theme.breakpoints.down("xs")]: {
      left: "4em",
    },
  },
  cardReactRight: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
}));

const AboutUs = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} ref={props.refProp}>
      <div className={`flex ${classes.heading}`}>
        <Typography className={classes.headingText}>About Us</Typography>
        <div className={classes.headingLine}></div>
        <Typography className={classes.headingPara}>
          We convert the business into a brand, our services are much flexible
          which fulfills promising results. We are always available on your back
          with our full support.
        </Typography>
      </div>
      <Container style={{ padding: "0px 20px" }}>
        {/* About 1 */}
        <div className={classes.cardWrapper}>
          <div className={classes.textCard}>
            <div
              style={{ background: "#1C93D8" }}
              className={`flex ${classes.cardRect}`}
            >
              <span style={{ zIndex: 1 }}>01</span>
            </div>
            <div
              style={{ background: "#1C93D8", left: "4em" }}
              className={`flex ${classes.icons}`}
            >
              <img src={TrophyIcon} />
            </div>
            <div className={classes.cardContent}>
              <Typography className={classes.cardContentHeading}>
                Our Mission
              </Typography>
              <Typography className={classes.para}>
                Our mission is to enhance the growth of business of our
                customers by providing the unique software solutions to our
                clients. In our business client satisfaction as our foremost
                priority. Our main aim is making each and every client’s project
                successful by providing our best services with more effective
                software solution strategies.
              </Typography>
            </div>
          </div>
          <div
            className={classes.imageCard}
            style={{
              background: `url(${About1})`,
              backgroundSize: "cover",
              backgroundPositionX: "center",
            }}
          >
            {/* <img src={About1} /> */}
          </div>
        </div>
        {/* About 2 */}
        <div className={classes.cardWrapper}>
          <div
            className={classes.imageCard}
            style={{
              background: `url(${About2})`,
              backgroundSize: "cover",
              backgroundPositionX: "center",
            }}
          >
            {/* <img src={About1} /> */}
          </div>
          <div className={classes.textCard}>
            <div
              style={{ background: "#FF595E" }}
              className={`flex ${classes.cardRect}`}
            >
              <span style={{ zIndex: 1 }}>02</span>
            </div>
            <div
              style={{ background: "#FF595E", left: "4em" }}
              className={`flex ${classes.icons}`}
            >
              <img src={PlanningIcon} />
            </div>
            <div className={classes.cardContent}>
              <Typography className={classes.cardContentHeading}>
                Our Plan
              </Typography>
              <Typography className={classes.para}>
                Our plan is to be recognized globally as one of the best
                Pakistan’s IT services provider. Pushing our products and
                services to reach on the international level will be one of our
                priority.<br></br>
                We want to build out our strategies that are best for our
                customers.
              </Typography>
            </div>
          </div>
        </div>
        {/* About 3 */}
        <div className={classes.cardWrapper}>
          <div className={classes.textCard}>
            <div
              style={{ background: "#FFA159", justifyContent: "flex-end" }}
              className={`flex ${classes.cardRect}`}
            >
              <span style={{ zIndex: 1 }}>03</span>
            </div>
            <div
              style={{ background: "#FFA159", left: "4em" }}
              className={`flex ${classes.icons}`}
            >
              <img src={VisionIcon} />
            </div>
            <div className={classes.cardContent}>
              <Typography className={classes.cardContentHeading}>
                Our Vision
              </Typography>
              <Typography className={classes.para}>
                Novasoft vision is inspiring our employees and customers to
                achieve their business goals. We want to become leading
                performer in providing the best quality web and software
                development solutions, and we want to grow as the best IT
                service provider in this competitive marketplace. We
                believe at work with the best quality and best service. We know
                that customer’s growth is our growth, we always help our
                customers to fulfill their goals. We satisfy our customers by
                our best services and want to know in this IT industry as the
                reliable and user- friendly software service provider.
              </Typography>
            </div>
          </div>
          <div
            className={classes.imageCard}
            style={{
              background: `url(${About3})`,
              backgroundSize: "cover",
              backgroundPositionX: "center",
            }}
          >
            {/* <img src={About1} /> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
