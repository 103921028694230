import React from "react";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    background: "white",
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
    overflow: "hidden",
    marginBottom: 10,
    marginTop: 10,
    "& a":{
      textDecoration:"none",
    }
  },
  img: {
    height: 200,
    width: "100%",
  },
  heading: {
    fontWeight: 600,
    color: "#1C93D8",
    fontSize: 20,
  },
  para: {
    fontSize: 14,
  },
  content: {
    padding: "16px 16px",
    paddingTop:10,
    height: "auto",
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  viewBtn: {
    padding: "10px 40px",
    marginTop: 20,
    marginBottom: 20,
    
    
    background: "#1C93D8",
    padding: "10px 30px",
    color: "white",
    boxShadow: "3px 7px 20px rgba(28,147,216,0.47)",
    marginTop: "20px",
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: "#1C93D8",
    },
  },
}));

const PortfolioCard = ({ title, para, src,link }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={src} className={classes.img} alt="" />
      <div className={classes.content}>
        <Typography className={classes.heading}>{title}</Typography>
        <Typography className={`oneLines ${classes.para}`}>{para}</Typography>
      </div>
      <Divider />
      <div className="flex">
        <a href={link}>
        <Button className={classes.viewBtn}>
          View
        </Button>
        </a>
      </div>
    </div>
  );
};

export default PortfolioCard;
