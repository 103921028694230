import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import NavPage from './NavPage';
import Navbar from '../Components/Navbar';
import AboutUs from '../Sections/AboutUs';
import ContactUs from '../Sections/ContactUs';
import Expertise from '../Sections/Expertise';
import Footer from '../Sections/Footer';
import Landing from '../Sections/Landing';
import OurServices from '../Sections/OurServices';
import Reviews from '../Sections/Reviews';
import Team from '../Sections/Team';
import GoUpBtn from '../Components/GoUpBtn';

const useStyles = makeStyles(theme => ({
    desktopScreen: {
        display: "block",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        }
    },
    mobileScreen: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        }
    },
}))

const Home = () => {
    const classes = useStyles();
    const [refs, setRefs] = React.useState({});
    const landingRef = React.useRef();
    const aboutRef = React.useRef();
    const servicesRef = React.useRef();
    const expertiseRef = React.useRef();
    const teamRef = React.useRef();
    const reviewRef = React.useRef();
    const contactRef = React.useRef();

    React.useEffect(() => {
        let obj = {
            landingRef,
            aboutRef,
            servicesRef,
            expertiseRef,
            teamRef,
            reviewRef,
            contactRef,
        }
        setRefs(obj);
    },[])

    return (
        <Container maxWidth="xl" style={{ padding: 0 }}>
            <div className={classes.mobileScreen}>
                <NavPage />
            </div>
            <div className={classes.desktopScreen}>
                <Navbar refs={refs} />
                <Landing refProp={landingRef} refs={refs} />
                <AboutUs refProp={aboutRef} />
                <OurServices refProp={servicesRef} />
                <Expertise refProp={expertiseRef} />
                <Team refProp={teamRef} />
                <Reviews refProp={reviewRef} />
                <ContactUs refProp={contactRef} />
                <Footer refs={refs}/>
                <GoUpBtn/>
            </div>
        </Container>
    );
}

export default Home;
