import React from 'react';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ExpertiseBg from '../Assets/Images/Expertise-Bg.png';
import HTMLIcon from '../Assets/Icons/html.svg';
import CSSIcon from '../Assets/Icons/css.svg';
import DockerIcon from '../Assets/Icons/docker.svg';
import FigmaIcon from '../Assets/Icons/figma.svg';
import FirebaseIcon from '../Assets/Icons/firebase.svg';
import FlutterIcon from '../Assets/Icons/flutter.svg';
import JSIcon from '../Assets/Icons/js.svg';
import MongoDBIcon from '../Assets/Icons/mongodb.svg';
import NodeIcon from '../Assets/Icons/node.svg';
import ReactIcon from '../Assets/Icons/react.svg';
import XDIcon from '../Assets/Icons/xd.svg';

//Old Style

// const cardStyles = makeStyles(({
//     // props => props.color
//     wrapper: {
//         background: "white",
//         padding: 20,
//         height: 250,
//         width: 250,
//         flexFlow: "column",
//         transition: "all 300ms ease-in-out",
//         cursor: "default",
//         overflow: "hidden",
//         "&:hover > div": {
//             borderWidth: "200px",
//             margin: "-200px",
//         },
//         "&:hover > p": {
//             color: "white",
//         },
//         "&:hover > div > div": {
//             background: "white",
//             border: "5px solid",
//             borderColor: props => props.secondary,
//         },
//     },
//     heading: {
//         fontSize: 24,
//         fontWeight: 800,
//         textAlign: "center",
//         marginTop: 20,
//         fontVariant: "all-petite-caps",
//         transition: "all 300ms ease-in-out",
//         zIndex: 2,
//     },
//     imageWrapper: {
//         background: props => props.secondary,
//         borderRadius: "50%",
//         width: 100,
//         height: 100,
//         border: "white 5px solid",
//         transition: "all 300ms ease-in-out",
//     },
//     imageContainer: {
//         border: `2px solid`,
//         borderColor: props => props.color,
//         margin: "-2px",
//         borderRadius: "50%",
//         transition: "all 300ms ease-in-out",
//         background: props => props.secondary,
//         position: "relative",
//     },
// }))

const cardStyles = makeStyles(theme=>({
    // props => props.color
    wrapper: {
        background: "white",
        padding: 20,
        height: 250,
        width: 250,
        flexFlow: "column",
        transition: "all 300ms ease-in-out",
        cursor: "default",
        overflow: "hidden",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        "&:hover > div": {
            borderWidth: "200px",
            margin: "-200px",
        },
        "&:hover > p": {
            color: "white",
        },
        "&:hover > div > div": {
            background: "white",
            border: "0px solid",
            borderColor: props => props.secondary,
        },
        [theme.breakpoints.down('sm')]:{
            height: "200px",
            width: "100%",
            
        }
    },
    heading: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: "center",
        marginTop: 20,
        fontVariant: "all-petite-caps",
        transition: "all 300ms ease-in-out",
        zIndex: 2,
    },
    imageWrapper: {
        background: props => props.secondary,
        borderRadius: "50%",
        width: 95,
        height: 95,
        border: "white 0px solid",
        transition: "all 300ms ease-in-out",
    },
    imageContainer: {
        border: `0px solid`,
        borderColor: props => props.secondary,
        margin: "0px",
        borderRadius: "50%",
        transition: "all 300ms ease-in-out",
        background: props => props.secondary,
        position: "relative",
    },
}))

const Card = (props) => {
    const classes = cardStyles(props);
    return (
        <div className={`flex ${classes.wrapper}`}>
            <div className={classes.imageContainer}>
                <div className={`flex ${classes.imageWrapper}`}>
                    <img width="70px" height="60px" src={props.img} alt={props.heading} />
                </div>
            </div>
            <Typography className={classes.heading}>
                {props.heading}
            </Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundImage: `url(${ExpertiseBg})`,
        backgroundColor: "#F8F8F8",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingBottom: 30,
    },
    heading: {
        flexFlow: "column",
        padding: "20px 0px",
        marginBottom: 20,
    },
    headingText: {
        fontSize: 40,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]:{
            fontSize: 20,
        }
    },
    headingLine: {
        height: 8,
        background: "#1C93D8",
        width: 124,
        borderRadius: 360,
        [theme.breakpoints.down('xs')]:{
            marginTop:10,
            height: 6,
        }
    },
    headingPara: {
        fontSize: 20,
        color: "black",
        textAlign: "center",
        maxWidth: 950,
        marginTop: 20,
        [theme.breakpoints.down('xs')]:{
            fontSize: 16,
            paddingLeft:10,
            paddingRight:10,
        }
    },
    cardsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit,250px)",
        rowGap: "20px",
        columnGap: "20px",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]:{
            display:"none",
        }
    },
    carousel:{
        display:"none",
        height:300,
        "& .react-multi-carousel-item":{
            padding:2,
        },
        [theme.breakpoints.down('sm')]:{
            display:"flex",
        }
    }

}))

const Expertise = (props) => {
    const classes = useStyles();
    const responsive = {
        tablet: {
            breakpoint: { max: 1000000, min: 550 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },

    };

    return (
        <div className={classes.wrapper} ref={props.refProp}>
            <div className={`flex ${classes.heading}`}>
                <Typography className={classes.headingText}>
                    Our Expertise
            </Typography>
                <div className={classes.headingLine}></div>
                <Typography className={classes.headingPara}>
                    We convert the business into a brand,
                    our services are much flexible which fulfills
                    promising results. We are always available on
                    your back with our full support.
                </Typography>
            </div>
            <Container style={{ padding: "0px 20px" }}>
                <div className={classes.cardsGrid}>
                    <Card color="rgba(255,61,0,1)" secondary="rgba(255,61,0,0.3)" img={HTMLIcon} heading="HTML" />
                    <Card color="rgba(33,150,243,1)" secondary="rgba(33,150,243,0.3)" img={CSSIcon} heading="CSS" />
                    <Card color="rgba(247,223,30,1)" secondary="rgba(247,223,30,0.3)" img={JSIcon} heading="Javascript" />
                    <Card color="rgba(97,230,255,1)" secondary="rgba(97,230,255,0.3)" img={ReactIcon} heading="React" />
                    <Card color="rgba(97,230,255,1)" secondary="rgba(97,230,255,0.3)" img={ReactIcon} heading="React Native" />
                    <Card color="rgba(0,86,158,1)" secondary="rgba(71,197,251,0.3)" img={FlutterIcon} heading="Flutter" />
                    <Card color="rgba(62,134,61,1)" secondary="rgba(104,159,99,0.3)" img={NodeIcon} heading="Node JS" />
                    <Card color="rgba(108,172,72,1)" secondary="rgba(108,172,72,0.3)" img={MongoDBIcon} heading="Mongo DB" />
                    <Card color="rgba(218,34,134,1)" secondary="rgba(218,34,134,0.3)" img={XDIcon} heading="Adobe XD" />
                    <Card color="rgba(162,89,255,1)" secondary="rgba(162,89,255,0.3)" img={FigmaIcon} heading="Figma" />
                    <Card color="rgba(252,202,63,1)" secondary="rgba(252,202,63,0.3)" img={FirebaseIcon} heading="Firebase" />
                    <Card color="rgba(27,129,165,1)" secondary="rgba(27,129,165,0.3)" img={DockerIcon} heading="Docker" />
                </div>
                <Carousel
                    className={classes.carousel}
                    transitionDuration={1000}
                    autoPlay infinite={true}
                    autoPlaySpeed={2000}
                    removeArrowOnDeviceType={["mobile","tablet"]}
                    showDots={true}
                    responsive={responsive}
                >
                    <Card color="rgba(255,61,0,1)" secondary="rgba(255,61,0,0.3)" img={HTMLIcon} heading="HTML" />
                    <Card color="rgba(33,150,243,1)" secondary="rgba(33,150,243,0.3)" img={CSSIcon} heading="CSS" />
                    <Card color="rgba(247,223,30,1)" secondary="rgba(247,223,30,0.3)" img={JSIcon} heading="Javascript" />
                    <Card color="rgba(97,230,255,1)" secondary="rgba(97,230,255,0.3)" img={ReactIcon} heading="React" />
                    <Card color="rgba(97,230,255,1)" secondary="rgba(97,230,255,0.3)" img={ReactIcon} heading="React Native" />
                    <Card color="rgba(0,86,158,1)" secondary="rgba(71,197,251,0.3)" img={FlutterIcon} heading="Flutter" />
                    <Card color="rgba(62,134,61,1)" secondary="rgba(104,159,99,0.3)" img={NodeIcon} heading="Node JS" />
                    <Card color="rgba(108,172,72,1)" secondary="rgba(108,172,72,0.3)" img={MongoDBIcon} heading="Mongo DB" />
                    <Card color="rgba(218,34,134,1)" secondary="rgba(218,34,134,0.3)" img={XDIcon} heading="Adobe XD" />
                    <Card color="rgba(162,89,255,1)" secondary="rgba(162,89,255,0.3)" img={FigmaIcon} heading="Figma" />
                    <Card color="rgba(252,202,63,1)" secondary="rgba(252,202,63,0.3)" img={FirebaseIcon} heading="Firebase" />
                    <Card color="rgba(27,129,165,1)" secondary="rgba(27,129,165,0.3)" img={DockerIcon} heading="Docker" />
                </Carousel>
            </Container >
        </div >
    );
}

export default Expertise;
