import React from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
//Images and Icons
import ServicesBg from '../Assets/Images/Services-Bg.jpg';
import Services1 from '../Assets/Images/Services-1.jpg';
import Services2 from '../Assets/Images/Services-2.jpg';
import Services3 from '../Assets/Images/Services-3.jpg';
import Services4 from '../Assets/Images/Services-4.jpg';
import Services5 from '../Assets/Images/Services-5.jpg';
import Services6 from '../Assets/Images/Services-6.jpg';

const serviceStyles = makeStyles((theme) => ({
    wrapper: {
        background: "white",
        boxShadow: "0px 5px 20px #00000012",
        padding: 20,
        transition: "transform 500ms ease-in-out",
        fontSize: "62.5%",
        transform: "translateZ(0)",
        // willChange: "transform",
        // marginBottom:20,
        position: "relative",
        "&::after": {
            content: '""',
            boxShadow: "0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15)",
            transition: "opacity 2s cubic-bezier(.165, .84, .44, 1)",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            height: "100%",
            width: "100%",
        },

        "&:hover": {
            // transform: "scale3d(1.006, 1.006, 1)",
            // transform: "scale(1.006)",
            "&::after": {
                opacity: 1,
            }

        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "50%",
            height: "500px",
            marginBottom:20,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "50%",
            // height: "560px",
            // height:"auto",
            marginBottom:20,
        }
    },
    heading: {
        fontSize: "2.6em",
        fontWeight: 600,
        color: "#1C93D8",
        textAlign: "center",
        margin: "20px 0",
    },
    para: {
        // fontSize: "1.6em",
        // fontWeight: "500",
        // fontFamily: "Roboto",
        // fontFamily: "-webkit-pictograph",
        // fontSmoothing: "subpixel-antialiased"
    },


}))

const ServiceCard = (props) => {
    const classes = serviceStyles();
    return (
        <div className={classes.wrapper}>
            <img width="100%" src={props.img} alt={props.heading} />
            <Typography className={classes.heading}>
                {props.heading}
            </Typography>
            <h5 className={`${classes.para} serviceCardPara`}>
                {props.para}
            </h5>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: `url(${ServicesBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingBottom: 30,
    },
    heading: {
        flexFlow: "column",
        padding: "20px 0px",
        marginBottom: 20,
    },
    headingText: {
        fontSize: 40,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]:{
            fontSize: 20,
        }
    },
    headingLine: {
        height: 8,
        background: "#1C93D8",
        width: 124,
        borderRadius: 360,
        [theme.breakpoints.down('xs')]:{
            marginTop:10,
            height: 6,
        }
    },
    servicesGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit,minmax(350px,1fr))",
        rowGap: "20px",
        columnGap: "20px",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        }
    },
    carousel: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        },
        [theme.breakpoints.down('xs')]: {
            height: "max-content",
        }
    }

}))

const OurServices = (props) => {
    const classes = useStyles();
    const responsive = {
        0: {
            items: 1,
        },
        600: {
            items: 2
        },
        960: {
            items: 3
        },
    }
    return (
        <div className={classes.wrapper} ref={props.refProp}>
            <div className={`flex ${classes.heading}`}>
                <Typography className={classes.headingText}>
                    Our Services
            </Typography>
                <div className={classes.headingLine}></div>
            </div>
            <Container style={{ padding: "0px 20px" }}>
                <div className={classes.servicesGrid}>
                    <ServiceCard
                        img={Services1}
                        heading="Web Development"
                        para="We convert your business 
                        idea into a fully-functional Web app. Novasoft handles every website 
                        just like their own with thousands of ideas we can turn your website 
                        into an attractive one. From the front to the back we have diversity of 
                        implementation methods."
                    />
                    <ServiceCard
                        img={Services2}
                        heading="UI/UX Design"
                        para="We also provide our clients the high quality and creative UI UX design services. Nova
                        soft is the best UI UX design services provider that has earned the trust of their clients
                        by providing the best quality services. We never disappoint our clients and created the
                        best UI UX designs that fulfill our clients aims and goals."
                    />
                    <ServiceCard
                        img={Services3}
                        heading="Mobile Development"
                        para="Novasoft company has 2 years’ experience in developing mobile apps all over the
                        world. Our developers build application on cross platform mobile app development that
                        can run on different platforms with only one single code system and with the best
                        quality. Our developer also provides digital services that fully meets your desires,
                        requirements and exceeds your expectations."
                    />
                    <ServiceCard
                        img={Services4}
                        heading="E-Commerce"
                        para="Novasoft provides a variety of ideas to have a digital store in 
                        a modern world with modern ways. Make your products enlighten with our
                         services. We build a greater, better and extremely well-organized 
                         e-commerce store. Let’s your product jump into the marker with our service."
                    />
                    <ServiceCard
                        img={Services5}
                        heading="SEO & Marketing"
                        para="In this digital world with this rapid advancement, digital marketing has come to become
                        the most important part of your business promotion and branding. We provide our
                        clients the perfect path to grow on digital platforms, and we also provide our clients the
                        best quality digital marketing solutions and help in achieving more leads and sales
                        through digital assets, and we believe that our experts give you the best result and best
                        services."
                    />
                    <ServiceCard
                        img={Services6}
                        heading="Graphic Design"
                        para="Novasoft company has 2 years’ experience in providing the best quality graphic design
                        services. Graphic design is an art, profession and projecting ideas where professionals
                        create visual or textual content to communicate through messages or ideas to social
                        group with objectives. Our expert’s graphic designer can design best and quality
                        graphic designs, and we serve our clients the best solutions for graphic design as per
                        your requirements and demands. We provide our clients outstanding designs with
                        modern colors, and the perfection they desire."
                    />
                </div>
                <div className={classes.carousel}>
                    <AliceCarousel mouseTracking
                        autoPlay={true}
                        infinite={true}
                        responsive={responsive}
                        autoHeight={false}
                        autoPlayInterval={2000}
                        disableButtonsControls={true}
                        items={[
                            <ServiceCard
                                img={Services1}
                                heading="Web Development"
                                para="We convert your business 
                                idea into a fully-functional Web app. Novasoft handles every website 
                                just like their own with thousands of ideas we can turn your website 
                                into an attractive one. From the front to the back we have diversity of 
                                implementation methods."
                            />,
                            <ServiceCard
                                img={Services2}
                                heading="UI/UX Design"
                                para="Looking for a perfect Web design for your website. It 
                        looks like you're on the right page. Novasoft provides purely 
                        unique designs for your website which perfectly describe your 
                        website. Whereas self-adaptable to all media screens that ever 
                        exist"
                            />,
                            <ServiceCard
                                img={Services3}
                                heading="Mobile Development"
                                para="Novasoft provide Hybrid application development services to 
                    our customers. As everything is getting smarter so does our ways. 
                    Let’s build a modern mobile application with a cross-browser functionality
                     with highly maintained UI/UX which will attracts every user 
                     to your application."
                            />,
                            <ServiceCard
                                img={Services4}
                                heading="E-Commerce"
                                para="Novasoft provides a variety of ideas to have a digital store in 
                    a modern world with modern ways. Make your products enlighten with our
                     services. We build a greater, better and extremely well-organized 
                     e-commerce store. Let’s your product jump into the marker with our service."
                            />,
                            <ServiceCard
                                img={Services5}
                                heading="SEO & Marketing"
                                para="Worried about your website ranking? Well not anymore. 
                    Novasoft has SEO professionals that provide the most advanced 
                    and effective SEO services for your website. Our expert’s 
                    strategy and the implementation makes any website road to 
                    the top in just less time."
                            />,
                            <ServiceCard
                                img={Services6}
                                heading="Graphic Design"
                                para="Marketing needs to be captivating but when it's 
                    about your business, it's the key. Graphics, animation, 
                    3D work, etc are the game changers to convert audience into 
                    customers. Our professionals explicitly provide you that specific 
                    brand look to achieve higher marketing goals."
                            />
                        ]} />
                </div>


            </Container >
        </div >
    );
}

export default OurServices;
