import { useEffect } from "react";
import "./App.css";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./Pages/Home";
import NavPage from "./Pages/NavPage";
import { GApageView, initGA } from ".";
// import ReactGA from "react-ga";

// ReactGA.initialize('G-6ZF9L9HB3M');

function App(props) {

  useEffect(() => {
    initGA();
    GApageView(window.location.pathname + window.location.search);
  }, []);

  // ReactGA.pageview(props.location.pathname);

 // // useEffect(() => {
 // //   ReactGA.pageview(window.location.pathname + window.location.search);
 // // }, []);


  // props.history.listen((location) => {
  //   ReactGA.set({ page: location.pathname });
  //   ReactGA.pageview(location.pathname);
  // });

  return (
    <Switch>
      <Route path="/" render={(props) => <Home {...props} />} exact />
      <Route path="/nav" render={(props) => <NavPage {...props} />} />
    </Switch>
  );
}

window.smoothScroll = function (target) {
  var scrollContainer = target;
  do {
    //find scroll container
    scrollContainer = scrollContainer.parentNode;
    if (!scrollContainer) return;
    scrollContainer.scrollTop += 1;
  } while (scrollContainer.scrollTop == 0);

  var targetY = 0;
  do {
    //find the top of target relatively to the container
    if (target == scrollContainer) break;
    targetY += target.offsetTop;
  } while ((target = target.offsetParent));

  var scroll = function (c, a, b, i) {
    i++;
    if (i > 30) return;
    c.scrollTop = a + ((b - a) / 30) * i;
    setTimeout(function () {
      scroll(c, a, b, i);
    }, 10);
  };
  // start scrolling
  scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
};

export default withRouter(App);
