import React from 'react';
import { makeStyles, Slide } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "fixed",
        top: "calc(100% - 115px)",
        left: "calc(100% - 70px)",
        height: 50,
        width: 50,
        background: "#222",
        borderRadius: "50%",
        // transition: "all 500ms ease-in-out !important",
        // transition: "all 500ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        color: "white",  
    },
    jiggle:{
        animation: "$jiggle 700ms infinite ease-out forwards",
    },
    "@keyframes jiggle":{
        "0%":{
            // transform: "translateX(0px)",
            // opacity:1
            boxShadow: "0px 0px 0px rgba(0,0,0,0.4)",
        },
        "25%":{
            // transform: "translateX(3px)",
        },
        "50%":{
            // opacity:0.8,
        },
        "75%":{
            // transform: "translateX(-3px)",
        },
        "100%":{
            // transform: "translateX(0px)",
            // opacity:1
            boxShadow: "0px 0px 0px 20px rgba(0,0,0,0.4)",
        }
    },
    iconWrapper:{
        width:"100%",
        height:"100%",
        // transition: "all 300ms ease-in-out",
        "&:hover": {
            // boxShadow: "0px 0px 10px 2px #1C93D8",
        }
    }
}))

const GoUpBtn = () => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const ref = React.createRef();

    const listener = (e) => {
        // console.log(parent)
        if (window.scrollY > 0 && !checked) setChecked(true);
        else if (window.scrollY <= 0) setChecked(false);
    }

    React.useEffect(() => {
        window.addEventListener('scroll', listener);
        return () => {
            window.removeEventListener('scroll', listener)
        }
    }, [])

    const scroll = () => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        // smooth
        window.smoothScroll(document.getElementById('root'));
    }

    const jiggleIn = ()=>{
        ref.current.classList.add(classes.jiggle);
    }

    const jiggleOut = ()=>{
        ref.current.classList.remove(classes.jiggle);
    }

    return <Slide direction="up" in={checked}  >
        <div ref={ref} className={`${classes.wrapper} flex`}  onMouseEnter={jiggleIn} onMouseLeave={jiggleOut}>
            <div className={`${classes.iconWrapper} flex`} >
                <ExpandLessIcon fontSize="large" onClick={scroll}  />
            </div>
            
        </div>
    </Slide>

}

export default GoUpBtn;