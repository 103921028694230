import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Logo from "../Assets/Logos/full.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: "#1A1A1A",
    paddingTop: "100px",
    paddingBottom: "20px",
    fontSize: "62.5%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "120px",
      fontSize: "50%",
    },
  },
  heading: {
    fontSize: "6em",
    color: "white",
    fontWeight: "800",
  },
  followUsText: {
    color: "#1C93D8",
    marginBottom: 5,
  },
  connects: {
    display: "flex",
    "& div": {
      width: "5.5em",
      height: "5.5em",
      padding: 12,
      background: "transparent",
      borderRadius: "50%",
      marginRight: 10,
      border: "1px solid #1C93D8",
      transition: "all 100ms ease-in-out",
      "&:hover": {
        background: "#1C93D8",
      },
      "&:hover svg": {
        fill: "white",
      },
    },
    "& div svg": {
      height: "100%",
      fill: "#1C93D8",
    },
    [theme.breakpoints.down("xs")]: {
      "& div": {
        width: "5em",
        height: "5em",
        padding: 10,
        marginRight: 5,
      },
    },
  },
  pages: {
    marginTop: 20,
    color: "white",
    display: "flex",
    height: "125%",
    justifyContent: "space-evenly",
    flexFlow: "column",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
    "& p": {
      fontWeight: 600,
      cursor: "pointer",
      fontSize: "1.6em",
    },
    "& p:hover": {
      color: "#1C93D8",
    },
  },
  icon: {
    color: "#1C93D8",
    marginRight: "10px",
    transform: "scale(1.2)",
    cursor: "pointer",
  },
  flex: {
    display: "flex",
  },
  footerphno: {
    display: "flex",
    textDecoration: "none",
    cursor: "pointer",
  },
  point: {
    cursor: "pointer",
  },
  contact: {
    color: "white",
    fontWeight: 600,
    margin: "20px 0px",
  },
  room: { padding: 10, paddingLeft: 0 },
  address: { color: "white", fontSize: "1.6em", cursor: "pointer" },
  num: { color: "white", fontSize: "1.6em" },
  mail: { color: "white", fontSize: "1.6em" },
  lightColor: { backgroundColor: "#1a1a1af5", color: "#ffffff" },
  reserved: { display: "flex", padding: "20px 40px", flexWrap: "wrap" },
}));

const Footer = ({ refs }) => {
  const classes = useStyles();
  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" + 24.940212653611113 + "," + 67.0795287010771
    );
  };
  const scroll = (name) => {
    refs[name].current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <div className={classes.wrapper}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={5}>
              <Typography className={classes.heading}>
                <img alt="logo" width="40%" src={Logo} />
              </Typography>
              <Typography className={classes.followUsText}>
                Follow Us
              </Typography>
              <div className={classes.connects}>
                <div
                  className={(`flex`, classes.point)}
                  //   style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://facebook.com/novasoft110", "_blank")
                  }
                >
                  <svg viewBox="0 0 32 32">
                    <path d="M19.253906 2C15.311906 2 13 4.0821719 13 8.8261719L13 13L8 13L8 18L13 18L13 30L18 30L18 18L22 18L23 13L18 13L18 9.671875C18 7.884875 18.582766 7 20.259766 7L23 7L23 2.2050781C22.526 2.1410781 21.144906 2 19.253906 2 z" />
                  </svg>
                </div>
                <div
                  className={(`flex`, classes.point)}
                  //   style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://github.com/NovaSoft110/", "_blank")
                  }
                >
                  <svg viewBox="0 0 32 32">
                    <path d="M16 4C9.371094 4 4 9.371094 4 16C4 21.300781 7.4375 25.800781 12.207031 27.386719C12.808594 27.496094 13.027344 27.128906 13.027344 26.808594C13.027344 26.523438 13.015625 25.769531 13.011719 24.769531C9.671875 25.492188 8.96875 23.160156 8.96875 23.160156C8.421875 21.773438 7.636719 21.402344 7.636719 21.402344C6.546875 20.660156 7.71875 20.675781 7.71875 20.675781C8.921875 20.761719 9.554688 21.910156 9.554688 21.910156C10.625 23.746094 12.363281 23.214844 13.046875 22.910156C13.15625 22.132813 13.46875 21.605469 13.808594 21.304688C11.144531 21.003906 8.34375 19.972656 8.34375 15.375C8.34375 14.0625 8.8125 12.992188 9.578125 12.152344C9.457031 11.851563 9.042969 10.628906 9.695313 8.976563C9.695313 8.976563 10.703125 8.65625 12.996094 10.207031C13.953125 9.941406 14.980469 9.808594 16 9.804688C17.019531 9.808594 18.046875 9.941406 19.003906 10.207031C21.296875 8.65625 22.300781 8.976563 22.300781 8.976563C22.957031 10.628906 22.546875 11.851563 22.421875 12.152344C23.191406 12.992188 23.652344 14.0625 23.652344 15.375C23.652344 19.984375 20.847656 20.996094 18.175781 21.296875C18.605469 21.664063 18.988281 22.398438 18.988281 23.515625C18.988281 25.121094 18.976563 26.414063 18.976563 26.808594C18.976563 27.128906 19.191406 27.503906 19.800781 27.386719C24.566406 25.796875 28 21.300781 28 16C28 9.371094 22.628906 4 16 4Z" />
                  </svg>
                </div>
                <div
                  className={(`flex`, classes.point)}
                  //   style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/novasoft110/",
                      "_blank"
                    )
                  }
                >
                  <svg viewBox="0 0 30 30">
                    <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z" />
                  </svg>
                </div>
                <div
                  className={(`flex`, classes.point)}
                  //   style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/novasoft110/",
                      "_blank"
                    )
                  }
                >
                  <svg viewBox="0 0 32 32">
                    <path d="M11.46875 5C7.917969 5 5 7.914063 5 11.46875L5 20.53125C5 24.082031 7.914063 27 11.46875 27L20.53125 27C24.082031 27 27 24.085938 27 20.53125L27 11.46875C27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7L20.53125 7C23.003906 7 25 8.996094 25 11.46875L25 20.53125C25 23.003906 23.003906 25 20.53125 25L11.46875 25C8.996094 25 7 23.003906 7 20.53125L7 11.46875C7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875C21.402344 9.1875 21 9.589844 21 10.09375C21 10.597656 21.402344 11 21.90625 11C22.410156 11 22.8125 10.597656 22.8125 10.09375C22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10C12.699219 10 10 12.699219 10 16C10 19.300781 12.699219 22 16 22C19.300781 22 22 19.300781 22 16C22 12.699219 19.300781 10 16 10 Z M 16 12C18.222656 12 20 13.777344 20 16C20 18.222656 18.222656 20 16 20C13.777344 20 12 18.222656 12 16C12 13.777344 13.777344 12 16 12Z" />
                  </svg>
                </div>
                <div
                  className={(`flex`, classes.point)}
                  //   style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://twitter.com/novasoft110/", "_blank")
                  }
                >
                  <svg viewBox="0 0 32 32">
                    <path d="M28 8.558594C27.117188 8.949219 26.167969 9.214844 25.171875 9.332031C26.1875 8.722656 26.96875 7.757813 27.335938 6.609375C26.386719 7.171875 25.332031 7.582031 24.210938 7.804688C23.3125 6.847656 22.03125 6.246094 20.617188 6.246094C17.898438 6.246094 15.691406 8.453125 15.691406 11.171875C15.691406 11.558594 15.734375 11.933594 15.820313 12.292969C11.726563 12.089844 8.097656 10.128906 5.671875 7.148438C5.246094 7.875 5.003906 8.722656 5.003906 9.625C5.003906 11.332031 5.871094 12.839844 7.195313 13.722656C6.386719 13.695313 5.628906 13.476563 4.964844 13.105469C4.964844 13.128906 4.964844 13.148438 4.964844 13.167969C4.964844 15.554688 6.660156 17.546875 8.914063 17.996094C8.5 18.109375 8.066406 18.171875 7.617188 18.171875C7.300781 18.171875 6.988281 18.140625 6.691406 18.082031C7.316406 20.039063 9.136719 21.460938 11.289063 21.503906C9.605469 22.824219 7.480469 23.609375 5.175781 23.609375C4.777344 23.609375 4.386719 23.585938 4 23.539063C6.179688 24.9375 8.765625 25.753906 11.546875 25.753906C20.605469 25.753906 25.558594 18.25 25.558594 11.742188C25.558594 11.53125 25.550781 11.316406 25.542969 11.105469C26.503906 10.410156 27.339844 9.542969 28 8.558594Z" />
                  </svg>
                </div>
                {/* <div
                  className={(`flex`, classes.point)}
                    style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC4OI556vWFkEp20ImcLkeSg",
                      "_blank"
                    )
                  }
                >
                  <svg viewBox="0 0 24 24">
                    <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z" />
                  </svg>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={2} style={{ height: "170px" }}>
              <div className={classes.pages}>
                <Typography onClick={() => scroll("landingRef")}>
                  Home
                </Typography>
                <Typography onClick={() => scroll("aboutRef")}>
                  About Us
                </Typography>
                <Typography onClick={() => scroll("servicesRef")}>
                  Services
                </Typography>
                <Typography onClick={() => scroll("teamRef")}>
                  Expertise
                </Typography>
                <Typography onClick={() => scroll("expertiseRef")}>
                  Teams
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Typography
                // style={{ color: "white", fontWeight: 600, margin: "20px 0px" }}
                className={classes.contact}
              >
                Contact Us
              </Typography>
              <Grid
                container
                spacing={3}
                // style={{ padding: 10, paddingLeft: 0 }}
                className={classes.room}
              >
                <Grid item xs={12} className={classes.flex}>
                  <RoomIcon
                    className={classes.icon}
                    onClick={() =>
                      window.open(
                        "https://goo.gl/maps/egPSeVTSLef9rgXv6",
                        "_blank"
                      )
                    }
                  />
                  <Typography
                    //   style={{ color: "white", fontSize: "1.6em" }}
                    className={classes.address}
                    onClick={showInMapClicked}
                  >
                    Plot C-6, Aziz Heaven, Block 17, F.B Area, Karachi, Pakistan
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.flex}>
                  <a className={classes.footerphno} href="tel:+92 314 3014551">
                    <PhoneIcon className={classes.icon} />
                    <Typography
                      // style={{ color: "white", fontSize: "1.6em"}}
                      className={classes.num}
                    >
                      +92 314 3014551
                    </Typography>
                  </a>
                </Grid>
                <Grid item xs={12} className={classes.flex}>
                  <a
                    style={{ cursor: "pointer" }}
                    className={classes.flex}
                    onClick={() => window.open("mailto:info@novasoft.tech")}
                  >
                    <EmailIcon className={classes.icon} />
                    <Typography
                      // style={{ color: "white", fontSize: "1.6em" }}
                      className={classes.mail}
                    >
                      info@novasoft.tech
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Grid
        container
        //    style={{ backgroundColor: "#1a1a1af5", color: "#ffffff" }}
        className={classes.lightColor}
      >
        <Grid
          item
          xs={12}
          // style={{ display: "flex", padding: '20px 40px',flexWrap:"wrap" }}
          
        >
          <Container className={classes.reserved}>
            <div style={{ flex: 1 }}>Designed & Developed by NovaSoft ®</div>
            <div>Copyright © 2021. All rights reserved</div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
