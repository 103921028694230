import React from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  OutlinedInput,
  Typography,
  CircularProgress
} from "@material-ui/core";
import ContactImg from "../Assets/Images/ContactImg.png";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import PeopleFacebookIcon from "../Assets/Icons/Team/facebook.svg";
import PeopleGithubIcon from "../Assets/Icons/Team/github.svg";
import PeopleInstagramIcon from "../Assets/Icons/Team/instagram.svg";
import PeopleTwitterIcon from "../Assets/Icons/Team/twitter.svg";
import PeopleYoutubeIcon from "../Assets/Icons/Team/youtube.svg";
import PeopleLinkedinIcon from "../Assets/Icons/Team/linkedin.svg";
import PunchLinesBg from "../Assets/Images/Contact-Blue-Bg.svg";

import swal from 'sweetalert';

import Axios from 'axios';
// import ContactUsEndPoint from '../Static/ContactUsEndPoint';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: "#F8F8F8",
    paddingBottom: 30,
    fontSize: "62.5%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "55%",
    },
  },
  heading: {
    flexFlow: "column",
    padding: "2em 0px",
    marginBottom: "2em",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0em",
    },
  },
  headingText: {
    fontSize: "4em",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  headingLine: {
    height: 8,
    background: "#1C93D8",
    width: 124,
    borderRadius: 360,
    marginBottom: "5em",
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      height: 6,
    },
  },
  haveAWordHeading: {
    fontSize: "4em",
    color: "#1C93D8",
    fontWeight: 800,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  contactPara: {
    fontSize: "1.4em",
    marginTop: "1em",
    marginBottom: "2em",
  },
  fieldHeading: {
    fontSize: "1.6em",
    fontWeight: 600,
    marginBottom: "5px",
  },
  inputField: {
    fontSize: "1.6em",
    width: "100%",
  },
  sendBtn: {
    background: "#1C93D8",
    padding: "10px 30px",
    color: "white",
    boxShadow: "3px 7px 20px rgba(28,147,216,0.47)",
    marginTop: "20px",
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: "#1C93D8",
    },
  },
  icon: {
    color: "#1C93D8",
    marginRight: "10px",
    transform: "scale(1.2)",
    cursor: "pointer",
  },
  connects: {
    display: "flex",
    marginTop: 20,
    "& div": {
      width: "5.5em",
      height: "5.5em",
      padding: 20,
      background: "white",
      borderRadius: "50%",
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        marginRight: 5,
        padding: 15,
        width: "4.5em",
        height: "4.5em",
      },
      [theme.breakpoints.down("xs")]: {},
    },
    "& img": {
      width: "2.5em",
      height: "2.5em",
    },
  },
  punchLinesWrapper: {
    transform: "translateY(50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  punchLines: {
    background: `url(${PunchLinesBg})`,
    backgroundSize: "cover",
    width: "90%",
    padding: "5em 6em",
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "3em 4em",
    },
  },
  counter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    cursor: "default",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      fontSize: "90%",
    },
  },
  counterLeft: {
    "&:after": {
      content: "''",
      position: "absolute",
      height: "90%",
      border: "1px solid rgba(255,255,255,0.2)",
      top: "5%",
      left: 0,
    },
    [theme.breakpoints.up("md")]: {
      "&:before": {
        content: "''",
        position: "absolute",
        height: "90%",
        border: "1px solid rgba(255,255,255,0.2)",
        top: "5%",
        left: "100%",
      },
    },
  },
  counterRight: {
    "&:after": {
      content: "''",
      position: "absolute",
      height: "90%",
      border: "1px solid rgba(255,255,255,0.2)",
      top: "5%",
      left: "100%",
    },
  },
  counterValue: {
    color: "white",
    fontSize: "4em",
    fontWeight: 600,
    textAlign: "center",
  },
  counterDescription: {
    color: "white",
    fontSize: "2em",
    fontWeight: 600,
    textAlign: "center",
  },
}));

const ContactUs = (props) => {
  const classes = useStyles();

  const [exp, setExp] = React.useState(0);
  const [projects, setProjects] = React.useState(0);
  const [products, setProducts] = React.useState(0);
  const [percent, setPercent] = React.useState(0);
  const counterRef = React.useRef();

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [disableSubmitButton, setDisableSubmitButton] = React.useState(false);

  const handleSubmit = async () => {

    let messageObject = {
      // eslint-disable-next-line
      ['Full Name']: name,
      // eslint-disable-next-line
      ['Email']: email,
      // eslint-disable-next-line
      ['Subject']: subject,
      // eslint-disable-next-line
      ['Message']: message
    }

    if (name !== '' && email !== '' && subject !== '' && message !== '') {
      setDisableSubmitButton(true);

      try {
        const res = await Axios({
          url: `https://formspree.io/f/xgezrbjw`,
          method: "POST",
          data: messageObject,
        })
        setDisableSubmitButton(false);
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        swal("Submitted", "Your message has been sent successfully. Please wait while we get back to you.!", "success");
      } catch (err) {
        console.log(err)
        swal("Error", "Some Error has occoured. Please try again later.", "error");
        setDisableSubmitButton(false);
      }
    } else {
      swal("Error", "Complete all information", "error");
    }
  }

  const increase = async (to, time, method) => {
    let init = 0;
    let speed = Math.round((to/100)*10);
    let interval = setInterval(async () => {
      await method(init);
      init = init+speed;

      if (init > to) clearInterval(interval);
    }, time);
  };

  const scrollCallback = () => {
    let wrapper = counterRef.current;
    const parentNode = props.parentNode
      ? props.parentNode
      : { current: window };
    let running = false;
    parentNode.current.addEventListener("scroll", () => {
      if (
        wrapper.getBoundingClientRect().y < window.innerHeight / 1.5 &&
        running === false
      ) {
        increase(7, 200, setExp);
        increase(400, 200, setProjects);
        increase(5, 200, setProducts);
        increase(100, 200, setPercent);
        running = true;
      }
      if (wrapper.getBoundingClientRect().y > window.innerHeight / 1.5) {
        setExp(0);
        setProjects(0);
        setProducts(0);
        setPercent(0);
        running = false;
      }
    });
  };

  const showInMapClicked = () => {
    window.open("https://maps.google.com?q=" + 24.940212653611113 + "," + 67.0795287010771);
  }

  React.useEffect(scrollCallback, [props.parentNode]);

  return (
    <div className={classes.wrapper} ref={props.refProp}>
      <div className={`flex ${classes.heading}`}>
        <Typography className={classes.headingText}>Contact Us</Typography>
        <div className={classes.headingLine}></div>
      </div>
      <Container>
        <Grid container spacing={3} style={{ padding: "0px 10px" }}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.haveAWordHeading}>
              Have a word with our Analyst.
            </Typography>
            <Typography className={classes.contactPara}>
              Novasoft team consists of highly knowledgeable and
              well-experienced developers. Our Expert developers are always
              ready to provide high-quality services on time and within budget.
              Our developers never disappoint our clients, and our main aim is
              to make our clients highly satisfied with our services. If you
              have any questions regarding your project, feel free to ask.
              <br />
              How can we help you?
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.fieldHeading}>
                  Full Name:
                </Typography>
                <OutlinedInput
                  placeholder="Full Name"
                  className={classes.inputField}
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.fieldHeading}>
                  Email Address:
                </Typography>
                <OutlinedInput
                  type="email"
                  placeholder="Email"
                  className={classes.inputField}
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.fieldHeading}>
                  Your Subject:
                </Typography>
                <OutlinedInput
                  type="email"
                  placeholder="Email"
                  className={classes.inputField}
                  onChange={e => setSubject(e.target.value)}
                  value={subject}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.fieldHeading}>
                  Message:
                </Typography>
                <OutlinedInput
                  multiline
                  rows={5}
                  placeholder="Write Message"
                  className={`${classes.inputField} ${classes.multiline}`}
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                />
              </Grid>
              <Grid item xs={12} className={`flex`}>
                {disableSubmitButton
                  ?
                  <Button className={classes.sendBtn}>
                    <CircularProgress style={{ color: 'white', marginRight: 10 }} /> Sending...
                  </Button>
                  :
                  <Button className={classes.sendBtn} onClick={handleSubmit}>Send</Button>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: 20 }}>
              <img width="100%" src={ContactImg} alt="contact" />
            </div>
            <Grid container spacing={3} style={{ padding: 10 }}>
              <Grid item xs={12} style={{ display: "flex" }}>
                <RoomIcon
                  onClick={() =>
                    window.open(
                      "https://goo.gl/maps/egPSeVTSLef9rgXv6",
                      "_blank"
                    )
                  }
                  className={classes.icon}
                />
                <Typography onClick={showInMapClicked} style={{ cursor: "pointer" }}>
                  Plot C-6, Aziz Heaven, Block 17, F.B Area, Karachi, Pakistan
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <a style={{ display: "flex", cursor: "pointer", textDecoration: "none" }} href="tel:+92 314 3014551">
                  <PhoneIcon className={classes.icon} />
                  <Typography style={{ color: "black" }} >+92 314 3014551</Typography>
                </a>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }} onClick={() => window.open("mailto:info@novasoft.tech")}>
                <EmailIcon
                  className={classes.icon}
                />
                <Typography style={{ cursor: "pointer" }}>info@novasoft.tech</Typography>
              </Grid>
            </Grid>
            <div className={classes.connects}>
              <div className="flex" style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open("https://facebook.com/novasoft110", "_blank")
                  }
                  alt="facebook"
                  src={PeopleFacebookIcon}
                />
              </div>
              <div className="flex" style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open("https://github.com/NovaSoft110/", "_blank")
                  }
                  alt="github"
                  src={PeopleGithubIcon}
                />
              </div>
              <div className="flex" style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/novasoft110/",
                      "_blank"
                    )
                  }
                  alt="linkedin"
                  src={PeopleLinkedinIcon}
                />
              </div>
              <div className="flex" style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/novasoft110/",
                      "_blank"
                    )
                  }
                  alt="instagram"
                  src={PeopleInstagramIcon}
                />
              </div>
              <div className="flex" style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open("https://twitter.com/novasoft110/", "_blank")
                  }
                  alt="twitter"
                  src={PeopleTwitterIcon}
                />
              </div>
              {/* <div className="flex" style={{ cursor: "pointer" }}>
                <img
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC4OI556vWFkEp20ImcLkeSg",
                      "_blank"
                    )
                  }
                  alt="youtube"
                  src={PeopleYoutubeIcon}
                />
              </div> */}
            </div>
          </Grid>
        </Grid>
        <div className={classes.punchLinesWrapper} ref={counterRef}>
          <div className={classes.punchLines}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={3} className={`${classes.counter}`}>
                <Typography className={`${classes.counterValue}`}>
                  {exp}
                </Typography>
                <Typography className={`${classes.counterDescription}`}>
                  Years
                  <br />
                  Experience
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                className={`${classes.counter} ${classes.counterLeft}`}
              >
                <Typography className={`${classes.counterValue}`}>
                  {projects}
                </Typography>
                <Typography className={`${classes.counterDescription}`}>
                  Completed
                  <br />
                  Projects
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                className={`${classes.counter} ${classes.counterRight}`}
              >
                <Typography className={`${classes.counterValue}`}>
                  {products}
                </Typography>
                <Typography className={`${classes.counterDescription}`}>
                  Completed
                  <br />
                  Products
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} className={`${classes.counter}`}>
                <Typography className={`${classes.counterValue}`}>
                  {percent}%
                </Typography>
                <Typography className={`${classes.counterDescription}`}>
                  Satisfied
                  <br />
                  Clients
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
