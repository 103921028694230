import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Logo from '../Assets/Logos/heading.png';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "absolute",
        top: 0,
        width: "100%",
        maxWidth: 1920,
        zIndex: "2000",
        transition: "width 300ms ease-in-out",
        // background: "#222",
        // background: "rgba(0,0,0,0.5)",
        // boxShadow: "0px 5px 10px rgba(0,0,0,0.5)",
    },
    navbar: {
        height: 70,
        display: "grid",
        gridTemplateColumns: "min-content 1fr repeat(7,max-content)",
        color: "white",
        transition: "width 200ms ease-in-out",
        overflow: "hidden",
        // background: "#222",
        // background: "rgba(0,0,0,0.1)",
    },
    logo: {
        fontSize: 35,
        fontWeight: "bold",
        cursor: "default",
        // paddingLeft: 30,
    },
    navItem: {
        paddingLeft: 30,
    },
    link: {
        fontSize: 16,
        cursor: "pointer",
        position: "relative",
        transition: "transform 100ms ease-out",
        "&:hover": {
            color: "#1C93D8",
            borderBottom: "4px solid #1C93D8",
            top: "2px",
            transform: "translateY(4px) scale(0.98)",
        },
    },

}))

const Navbar = ({ refs }) => {
    const classes = useStyles();

    const scroll = (name) => {
        refs[name].current.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div className={`wrapper ${classes.wrapper}`}>
            <Container maxWidth="lg">
                <div className={classes.navbar}>
                    <div className={`flex`}>
                        <img className={classes.logo} height="30px" src={Logo} />
                    </div>
                        <div>

                        </div>
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("landingRef")}>Home</Typography>
                    </div>
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("aboutRef")}>About Us</Typography>
                    </div>
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("servicesRef")}>Services</Typography>
                    </div>
                    
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("expertiseRef")}>Expertise</Typography>
                    </div>
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("teamRef")}>Teams</Typography>
                    </div>
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("reviewRef")}>Testimonal</Typography>
                    </div>
                    <div className={`flex ${classes.navItem}`}>
                        <Typography className={classes.link} onClick={() => scroll("contactRef")}>Contact</Typography>
                    </div>
                </div>

            </Container>

        </div>
    );
}

export default Navbar;
