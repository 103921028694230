import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

//index.js
import ReactGA from "react-ga";

export const initGA = () => {
  const TRACKING_ID = "G-6ZF9L9HB3M";
  ReactGA.initialize(TRACKING_ID);
};

export const GApageView = (page) => {
  console.log(page);
  ReactGA.pageview(page);
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
