import Zaman from "../Assets/Images/People/zaman.jpg";
import Hamza from "../Assets/Images/People/hamza.jpg";
import Nouman from "../Assets/Images/People/nouman.jpg";
import Yasir from "../Assets/Images/People/yasir.jpg";
import Shahbaz from "../Assets/Images/People/shahbaz.jpg";
import Saqlain from "../Assets/Images/People/saqlain.jpg";

const TeamData = {
  nouman: {
    name: "Nouman Suleman",
    title: "Co-Founder & Graphics Head",
    img: Nouman,
    facebook: "https://www.facebook.com/profile.php?id=100004915128539",
    dribble: "https://dribbble.com/Nouman007",
    linkedin: "http://linkedin.com/in/nouman-suleman-0a03291a6",
    twitter: "https://twitter.com/brown_saiyan11",
    // instagram: "https://www.instagram.com/nouman_suleman/",
    para: `We have 2 years of experience in providing the best quality graphic design services. 
              Graphic design is an art, profession, and projecting ideas where professionals create visual 
              or textual content to communicate through messages or ideas to a social group with objectives. 
              We serve our clients the best solutions for graphic design as per your requirements and demands. 
              We provide outstanding designs with modern colors, and the perfection they desire. 
              We also provide the high quality and creative UI UX design services. 
              Novasoft is the best UI UX design services provider that has earned the trust of 
              its clients by providing the best quality services.`,
  },
  yasir: {
    name: "Yasir Ahmed",
    title: "Co-Founder & Mobile Development Head",
    img: Yasir,
    facebook: "https://www.facebook.com/Theyasirahmad/",
    github: "https://github.com/theyasirahmad",
    linkedin: "https://www.linkedin.com/in/theyasirahmad/",
    // twitter: "twitter.com",
    // instagram: "instagram.com",
    para: `We have 2 years of experience in developing mobile apps all over the world. 
              Our developers builds a cross-platform mobile apps which comprise of only single code 
              system while providing best quality. We also provide outstanding digital services around the globe. 
              Our main aim is to deliver MERN Stack applications which follow ever evolving digital world 
              requirements for better reach and performance propelling your business in the international 
              market only with the help of our creative decisions and powerful backend solutions.`,
  },
  shahbaz: {
    name: "Muhammad Shahbaz Khan",
    title: "Co-Founder, SEO & Digital Marketing Head",
    img: Shahbaz,
    facebook: "https://www.facebook.com/profile.php?id=100004199987928",
    // github: "github.com",
    linkedin: "https://www.linkedin.com/in/shahbaz-khan-153625159/",
    // twitter: "twitter.com",
    instagram: "https://www.instagram.com/sha__bi/",
    para: `For more than 2 years, we have been providing SEO solutions to our clients to 
              make their page become apparent on the first page and make their business rank at the top. 
              Our SEO experts are advanced in keyword research, increase organic traffic on your website 
              and build organic rankings. In this digital world with this rapid advancement, digital 
              marketing has come to become the most important part of your business promotion and branding. 
              We provide our clients the perfect path to grow on digital platforms, and we also provide our 
              clients the best quality digital marketing solutions and help in achieving more leads and sales 
              through digital assets, and we believe that our experts give you the best result and best services. `,
  },
  zaman: {
    name: "Syed Muhammad Zaman Mehdi",
    title: "Frontend Head",
    img: Zaman,
    facebook: "https://www.facebook.com/mehdi.zaman110/",
    github: "https://github.com/ZamanMehdi",
    linkedin: "https://www.linkedin.com/in/syedmuhammadzamanmehdi/",
    // twitter: "twitter.com",
    instagram: "https://www.instagram.com/mehdi__zaman/",
    para: `We build out a pattern that emphasizes the efficiency of sites or software. 
                  We are highly proficient in React JS, JavaScript, CSS ,SAAAS or HTML. 
                  Our developers have strong skills and the ability to work in the JavaScript framework. 
                  We can develop our client's applications within a minimum amount of time at an affordable price. 
                  Our team believes in enhancing our expertise in various techniques to provide you the best quality services. 
                  Our main aim is to deliver the best results to our clients and MERN stack development 
                  services can give the best solutions.`,
  },
  // hamza: {
  //   name: "Muhammad Hamza Khan",
  //   title: "Co-Founder & Backend Head",
  //   img: Hamza,
  //   facebook: "https://www.facebook.com/hamza.khan91/",
  //   github: "https://github.com/hamza91able",
  //   linkedin: "https://www.linkedin.com/in/datguyhamza",
  //   // twitter: "twitter.com",
  //   instagram: "https://www.instagram.com/datguyhamzaa/",
  //   para: `We can develop our client's applications within a minimum amount of time at an affordable price.
  //             Our team believes in enhancing our expertise in various techniques to provide you the best quality
  //             services, so that, your business get progress in this industry and stand out your business in this
  //             digital world. Our talented and creative developer is specialized in providing high-quality backend
  //             development services and creating solutions that will help you to achieve your business goals and
  //             carry out your plans. Whatever your requirements,
  //             our team will provide you high-quality backend development solutions on time and within budget.`,
  // },
  saqlain: {
    name: "Syed Muhammad Saqlain",
    title: "Frontend Developer",
    img: Saqlain,
    // facebook: "https://www.facebook.com/saqlain1020",
    // github: "https://github.com/saqlain1020",
    // linkedin: "https://www.linkedin.com/in/saqlain1020/",
    facebook: "https://www.facebook.com/",
    github: "https://github.com/",
    linkedin: "https://www.linkedin.com/",
    // twitter: "twitter.com",
    // instagram: "https://www.instagram.com/saqlain_ig1020/",
    instagram: "https://www.instagram.com/",
    para: `I'm an ambitious problem solver with a passion for learing and implementing new solutions,
          I have been working on React Js for almost a year now, currently providing services like transforming
          your mockup designs to full fledge working frontend application. I believe in enhancing my expertise in 
          various techniques to provide you the best quality service and beautiful UI/UX experience in your App.`,
  },
};

export default TeamData;
