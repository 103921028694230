import React from "react";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import PortfolioCard from "../Components/PortfolioCard";
import P1 from "./../Assets/Images/Projects/1.png";
import P2 from "./../Assets/Images/Projects/2.png";
import P3 from "./../Assets/Images/Projects/3.png";
import P4 from "./../Assets/Images/Projects/4.png";
import N1 from "./../Assets/Images/Projects/N1.png";
import N2 from "./../Assets/Images/Projects/N2.png";
import N3 from "./../Assets/Images/Projects/N3.png";
import N4 from "./../Assets/Images/Projects/N4.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    background: "rgba(0,0,0,0.03)",
    paddingTop: 20,
    paddingBottom: 30,
  },
  topHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBtn: {
    borderRadius: 0,
    borderColor: "#1C93D8 !important",
    color: "#1C93D8",

    "&:hover": {
      background: "#1C93D8",
      color: "white",
    },
  },
  heading: {
    fontWeight: 500,
    fontSize: 28,
  },
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 900 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1260, min: 950 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 950, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const portfolioData = [
  // {
  //   title: "Bscience App",
  //   para: `React JS, Material UI Crypto Pool App.`,
  //   src: P1,
  //   link: "https://app.bscience.finance/Farm",
  // },
  {
    title: "AFTS Web",
    para: `React JS, Material UI Energy Sustainability Web`,
    src: P2,
    link: "https://afts.biz/",
  },
  {
    title: "IgLearn Platform",
    para: `React JS, Material UI Learning Platform`,
    src: P3,
    link: "http://languagecenter.intellogeek.com/",
  },
  {
    title: "EZPro Home",
    para: `Service Web on React JS.`,
    src: P4,
    link: "https://ezprohome.com/",
  },
  {
    title: "Artist Portfolio Page",
    para: `UI design created on XD.`,
    src: N1,
    link: "https://dribbble.com/shots/14923964-Artist-Portfolio-website",
  },
  {
    title: "Food Ordering App",
    para: `UI design created on XD.`,
    src: N2,
    link: "https://dribbble.com/shots/14808340-Food-Ordering-App",
  },
  {
    title: "Music App",
    para: `UI design created on XD.`,
    src: N3,
    link: "https://dribbble.com/shots/13452190-Music-Pro",
  },
  {
    title: "Developer Portfolio",
    para: `UI design created on XD.`,
    src: N4,
    link: "https://dribbble.com/shots/14923964-Artist-Portfolio-website",
  },
];

const Portfolio = () => {
  const classes = useStyles();
  const ref = React.useRef();

  const handleNext = () => {
    ref.current.next();
  };
  const handlePrev = () => {
    ref.current.previous();
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.topHeading}>
          <Typography className={classes.heading}>
            Our Latest Projects
          </Typography>
          <div>
            <div className={classes.topHeading} style={{ width: 140 }}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.arrowBtn}
                onClick={handlePrev}
              >
                <KeyboardArrowLeftIcon />
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.arrowBtn}
                onClick={handleNext}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div>
          </div>
        </div>
        <Carousel
          ref={ref}
          infinite={true}
          removeArrowOnDeviceType={[
            "tablet",
            "mobile",
            "desktop",
            "superLargeDesktop",
          ]}
          responsive={responsive}
        >
          {portfolioData.map((item, index) => (
            <PortfolioCard key={index} {...item} />
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Portfolio;
