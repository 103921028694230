import React, { useState } from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import AboutUs from '../Sections/AboutUs';
import ContactUs from '../Sections/ContactUs';
import Expertise from '../Sections/Expertise';
import Footer from '../Sections/Footer';
import Landing from '../Sections/Landing';
import OurServices from '../Sections/OurServices';
import Reviews from '../Sections/Reviews';
import Team from '../Sections/Team';
import { Container } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import Logo from '../Assets/Logos/heading.png';
import MenuIcon from '@material-ui/icons/Menu';
import GoUpBtn from "../Components/GoUpBtn";

const Phone = styled.div`
//   position: relative;
  background: #1C93D8;
  height: 100vh;
  max-height:1100px;
  overflow-x:hidden;
`;

const openStyles = css
  `transform: translateX(110px) scale(0.97); border-radius: 40px;  margin-left : 10px;`
  ;

const Content = styled.div`
  overflow-y:scroll;
  height: 100%;
  overflow-x:hidden;
  background: rgba(255,255,255,0.9);
  transform: translateX(0) scale(1);

  transition: 0.45s;
  ${(p) => p.open && openStyles};
`;

const Nav = styled.nav`
  position: absolute;
  top: 60px;
  transform: translateY(20px);
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  padding: 0 1rem;
  border: 0;
  background: transparent;
  color: ${rgba("white", 1)};
  cursor: pointer;

  &:hover {
    color: ${rgba("white", 0.85)};
  }
`;

const NavIcon = styled.span`
  margin-right: 0.5rem;
  font-size: 20px;
`;

const NavText = styled.span`
  font-size: 18px;
`;

const burgerStyles = css
  `transform: translateX(110px) scale(0.97); border-radius: 40px;opacity:0;`
  ;

const BurgerMenu = styled.div`
  transform: translateX(0) scale(1);
  transition: 0.45s;
  height: 50px;
  width:50px;
  position: absolute;
  right:0px;
  // top:10px;
  opacity:1;
`;

const NavPage = () => {
  const [open, setOpen] = useState(false);
  const contentRef = React.useRef();
  const [refs, setRefs] = React.useState({});
  const landingRef = React.useRef();
  const aboutRef = React.useRef();
  const servicesRef = React.useRef();
  const expertiseRef = React.useRef();
  const teamRef = React.useRef();
  const reviewRef = React.useRef();
  const contactRef = React.useRef();
  const [scrollTop, setScrollTop] = React.useState(0);

  React.useEffect(() => {
    let obj = {
      landingRef,
      aboutRef,
      servicesRef,
      expertiseRef,
      teamRef,
      reviewRef,
      contactRef,
    }
    setRefs(obj);
  }, [])

  const scroll = (name) => {
    refs[name].current.scrollIntoView({ behavior: "smooth" });
    setOpen(false)
  }

  return (
    <Container maxWidth="xl" style={{ maxHeight: 1100 }} disableGutters>
      <Phone>
        <Nav>
          {open && <div style={{ position: "absolute", color: "white", top: -50, left: 45 }}>
            <CloseIcon onClick={() => setOpen(false)} fontSize="large" />
          </div>}
          <NavButton onClick={() => scroll("landingRef")} style={{ opacity: !open ? "0" : "1", }}>
            <NavIcon>I</NavIcon>
            <NavText>Home</NavText>
          </NavButton>
          <NavButton onClick={() => scroll("aboutRef")} style={{ opacity: !open ? "0" : "1", }}>
            <NavIcon>I</NavIcon>
            <NavText>About Us</NavText>
          </NavButton>
          <NavButton onClick={() => scroll("servicesRef")} style={{ opacity: !open ? "0" : "1", }}>
            <NavIcon>I</NavIcon>
            <NavText>Services</NavText>
          </NavButton>
          <NavButton onClick={() => scroll("expertiseRef")} style={{ opacity: !open ? "0" : "1", }}>
            <NavIcon>I</NavIcon>
            <NavText>Expertise</NavText>
          </NavButton>
          <NavButton onClick={() => scroll("teamRef")} style={{ opacity: !open ? "0" : "1", }}>
            <NavIcon>I</NavIcon>
            <NavText>Teams</NavText>
          </NavButton>
          <NavButton onClick={() => scroll("reviewRef")} style={{ opacity: !open ? "0" : "1" }}>
            <NavIcon>I</NavIcon>
            <NavText>Testimonal</NavText>
          </NavButton>
          <NavButton onClick={() => scroll("contactRef")} style={{ opacity: !open ? "0" : "1", }}>
            <NavIcon>I</NavIcon>
            <NavText>Contact</NavText>
          </NavButton>
        </Nav>
        <BurgerMenu
          onClick={() => setOpen(true)}
          style={{
            zIndex: 99,
            opacity: open ? "0" : "1",
          }}>
          <MenuIcon id="navmobileburger" fontSize="large"
          />
        </BurgerMenu>
        <Content ref={contentRef} open={open} className="navMainContentScroll">
          <img src={Logo} height="25px" style={{ margin: 20, position: "absolute" }} />
          <Landing refProp={landingRef} getStarted={() => scroll("aboutRef")} />
          <AboutUs refProp={aboutRef} />
          <OurServices refProp={servicesRef} />
          <Expertise refProp={expertiseRef} />
          <Team refProp={teamRef} />
          <Reviews refProp={reviewRef} />
          <ContactUs parentNode={contentRef} refProp={contactRef} />
          <Footer />
        </Content>
      </Phone>
    </Container>
  );
};

export default NavPage;
